.tab {
  display: flex;
  height: 24px;

  &-item {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-right: 35px;
  }

  &-item-active {
    color: #fff;
    background: #7f76bd;
  }
}

.btn-wrap {
  display: flex;
  margin-top: 8px;
  line-height: 24px;

  .creatNewBtn {
    background-image: url("../../assets/newscr_btn_n@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 212px;
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    cursor: pointer;
  }

  .title {
    margin-left: 30px;
    box-sizing: border-box;
    width: 96px;
    text-align: center;
    height: 24px;
    background: #fae1ee;
    border-radius: 4px;
    border: 1px solid #f394ac;
    z-index: 2;
    padding: 0;
    ;
  }

  .btn {
    cursor: pointer;
    width: 62px;
    text-align: center;
    background: #7f76bd;
    border-radius: 4px;
    padding-left: 10px;
    font-size: 14px;
    color: #fff;
    margin-left: -10px;
  }
}

.select-wrap {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  .select-item {
    width: 100px;
    margin-right: 20px;
  }
}

.table-filter {
  height: 105px;
}

.item-title {
  display: -webkit-box; //弹性盒子布局
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; //
  overflow: hidden;
}

.shenglue-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.position-btn {
  text-align: center;
  border: 1px solid #c5c0e0;
  width: 40px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 14px;
}
.delete-btn {
  color: #F394AC;
}

.relative-left-10 {
  position: relative;
  left: 10px;
}

.relative-left-25 {
  position: relative;
  left: 25px;
}
.relative-left-35 {
  position: relative;
  left: 35px;
}
