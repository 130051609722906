@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.dialog-content {
  .step1 {
    display: flex;
    justify-content: center;
  }
  .step2 {
    display: flex;
    margin-top: 25px;
    margin-left: 9px;
  }
  .step3 {
    height: 45vh;
    overflow-y: scroll;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    .item {
      margin-bottom: 40px;
      margin-right: 20px;
      width: 198px;
      cursor: pointer;
      .item-image {
        height: 148px;
        background: rgba(236,234,250,1);
        border-radius: 4px;
        position: relative;
        .right {
          position: absolute;
          top: 0px;
          right: 0;
        }
      }
      .item-message {
        font-size: 14px;
        .row1 {
          margin-top: 10px;
        }
        .row2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .step4 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
